import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Footer from '../components/Footer'
import Header from '../components/Header'
import vraa01 from '../images/vraa01.png'
import Logo from '../images/top_mainimage.png'

class VRAAPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        }
        this.handleOpenArticle = this.handleOpenArticle.bind(this)
        this.handleCloseArticle = this.handleCloseArticle.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {

        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout
            })
        }, 350)

    }

    handleCloseArticle() {

        this.setState({
            articleTimeout: !this.state.articleTimeout
        })

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout
            })
        }, 325)

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: ''
            })
        }, 350)

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }




    render() {
        return (
            <Layout location={this.props.location} >
                <div>
                    <Header />
                    <div id="vraa">
                        <div className="vraalist">
                            <div className="title">「Virtual Reality Architecture Award（VRAA）」とは？</div>
                            <div className="vraadescription">Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。<br />
                                2019年に開催された『VRAA01』では、「バーチャル/コミュニケーション」をテーマに総計79点のVRワールド作品が応募されました。<br />
                                VR上に構築されたさまざまな空間/体験をより多くの人が楽しめる場をつくるとともに、VRAAはVR空間で生きるクリエイターの先進的で豊かなクリエイションの発信を支援します。<br /><br />

                                なお、アワード名称の"Architecture"とは、何らかの意志とそれを実現する仕組み（アーキテクチャ）を持った空間のことを指します。<br />
                                こういう楽しさや豊かさを生み出したい、という作者の思いが空間/体験となって現れたものであれば、それはアーキテクチャです。<br />
                                それぞれの思いの詰まったVR空間を、ぜひ気軽にご応募ください。</div>

                        </div>

                    </div>
                    <div id="vraa01">
                        <div className="information">
                        <div className="number">VRAA01</div>
                           
                    <div className="theme">バーチャルコミュニケーション</div>
                    </div>
                        <a href="https://xrarchi.org/vraa1/" target="_blank"><img src={vraa01} /></a>
                    </div>
                    <div id="wrapper-footer">
                        <Footer timeout={this.state.timeout} />
                    </div>
                    <div id="bg-vraa"></div>
                </div>
                <Helmet>
                    <html lang="jp" />
                    <title>VRAA</title>
                    <meta name="description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。" />
                    <meta property="og:site_name" content="VRAA02" />
                    <meta property="og:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="VRAA02" />
                    <meta name="twitter:description" content="Virtual Reality Architecture Award(VRAA)は、人類の生き方や楽しみ方をアップデートするVR空間＝ワールドを幅広く募集するデザインアワードです。" />
                    <meta name="twitter:image" content='https://vraa.jp/static/top_mainimage-fbe5e76929f9ae30bdaac989a322d96b.png' />
                </Helmet>
            </Layout>
        )
    }
}

export default VRAAPage
